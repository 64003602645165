<div class="toolbar-container" [@expand]="_display ? 'expanded' : 'collapsed'">
    @if (showScrollCtrls) {
        <button class="scroll-ctrl ctrl-prev" (click)="scrollNextElem('L')">
            <mat-icon>chevron_left</mat-icon>
        </button>
    }
    <div class="filters" [ngClass]="{ 'p-0': !_display }" #filtersElem>
        <div
            class="filter"
            *ngFor="let filter of config | keyvalue: objOrder"
            (keydown.enter)="focusScroll(target)"
            (click)="focusScroll(target)"
            #target
        >
            <div class="input-group" [ngClass]="{ 'with-value': values?.[filter.key]?.displayValue }">
                @switch (filter.value.type) {
                    @case ('text') {
                        @if (!_currentValues?.[filter.key]?.value) {
                            <input
                                matInput
                                #textValue
                                type="text"
                                class="form-control"
                                [placeholder]="filter.value.label"
                                [value]="_currentValues?.[filter.key]?.value"
                                (keydown.enter)="applyFilter(filter.key, $event)"
                            />
                            <button mat-icon-button matSuffix (click)="applyFilter(filter.key, textValue.value)">
                                <mat-icon [ngClass]="{ 'input-lens': !textValue.value }"> search </mat-icon>
                            </button>
                        } @else {
                            <span>{{ _currentValues[filter.key].value }}</span>
                            @if (values?.[filter.key]?.value && !filter.value.required) {
                                <button
                                    class="clear-filter-btn"
                                    mat-icon-button
                                    (click)="$event.stopPropagation(); clearFilters(filter.key)"
                                >
                                    <mat-icon> close </mat-icon>
                                </button>
                            }
                        }
                    }

                    @case ('number') {
                        @if (!_currentValues?.[filter.key]?.value) {
                            <input
                                matInput
                                #numberValue
                                onlyNumbers
                                type="text"
                                class="form-control"
                                [placeholder]="filter.value.label"
                                [value]="_currentValues?.[filter.key]?.value"
                                (keydown.enter)="applyFilter(filter.key, $event)"
                            />
                            <button mat-icon-button matSuffix (click)="applyFilter(filter.key, numberValue.value)">
                                <mat-icon [ngClass]="{ 'input-lens': !numberValue.value }"> search </mat-icon>
                            </button>
                        } @else {
                            <span>{{ _currentValues[filter.key].value }}</span>
                            @if (values?.[filter.key]?.value && !filter.value.required) {
                                <button
                                    class="clear-filter-btn"
                                    mat-icon-button
                                    (click)="$event.stopPropagation(); clearFilters(filter.key)"
                                >
                                    <mat-icon> close </mat-icon>
                                </button>
                            }
                        }
                    }

                    @case ('number-range') {
                        @if (!_currentValues?.[filter.key]?.displayValue) {
                            <button
                                mat-button
                                [matMenuTriggerFor]="numberRangeMenu"
                                (menuOpened)="resetInput(filter.key)"
                            >
                                {{ filter.value.label }}
                            </button>
                        } @else {
                            <div
                                class="d-flex align-items-center"
                                [matMenuTriggerFor]="numberRangeMenu"
                                (menuOpened)="resetInput(filter.key)"
                            >
                                <span>{{ _currentValues[filter.key].displayValue }}</span>
                                @if (values?.[filter.key]?.value && !filter.value.required) {
                                    <button
                                        class="clear-filter-btn"
                                        mat-icon-button
                                        (click)="$event.stopPropagation(); clearFilters(filter.key)"
                                    >
                                        <mat-icon> close </mat-icon>
                                    </button>
                                }
                            </div>
                        }
                        <mat-menu #numberRangeMenu="matMenu" class="agd-context-menu">
                            <div
                                class="d-flex flex-column p-3"
                                matMenuContent
                                (click)="$event.stopPropagation()"
                                (keydown.tab)="$event.stopPropagation()"
                            >
                                <span><b>Define un rango</b></span>
                                <mat-form-field class="mt-2">
                                    <input
                                        matInput
                                        onlyNumbers
                                        type="number"
                                        inputmode="numeric"
                                        [ngModel]="getNumberRangeValue(filter.key, 'from')"
                                        (ngModelChange)="onNumberRangeChange(filter.key, $event, 'from')"
                                        placeholder="Desde"
                                    />
                                </mat-form-field>
                                <mat-form-field class="mt-2">
                                    <input
                                        matInput
                                        onlyNumbers
                                        type="number"
                                        inputmode="numeric"
                                        [ngModel]="getNumberRangeValue(filter.key, 'to')"
                                        (ngModelChange)="onNumberRangeChange(filter.key, $event, 'to')"
                                        placeholder="Hasta"
                                    />
                                </mat-form-field>
                                @if (
                                    _currentValues?.[filter.key]?.value && _currentValues?.[filter.key]?.applyDisabled
                                ) {
                                    <small class="text-danger mt-2"> El rango ingresado es inválido </small>
                                }
                                <div class="d-flex align-items-center w-100 mt-2">
                                    @if (values?.[filter.key]?.value) {
                                        <button
                                            class="flex-grow-1 mr-2 height-40px"
                                            mat-button
                                            (click)="clearFilters(filter.key)"
                                        >
                                            Limpiar
                                        </button>
                                    }

                                    <button
                                        class="flex-grow-1 height-40px"
                                        mat-raised-button
                                        color="primary"
                                        [disabled]="_currentValues?.[filter.key]?.applyDisabled"
                                        (click)="applyFilter(filter.key, _currentValues?.[filter.key]?.value)"
                                    >
                                        Aplicar
                                    </button>
                                </div>
                            </div>
                        </mat-menu>
                    }

                    @case ('radio') {
                        <mat-select
                            color="primary"
                            [hideSingleSelectionIndicator]="true"
                            [placeholder]="filter.value.label"
                            [value]="_currentValues?.[filter.key]?.value"
                            (valueChange)="applyFilter(filter.key, $event)"
                        >
                            <mat-option *ngFor="let option of filter.value.options | async" [value]="option.value">
                                {{ option.label | titlecase }}
                            </mat-option>
                        </mat-select>
                        @if (values?.[filter.key]?.value && !filter.value.required) {
                            <button
                                class="clear-filter-btn"
                                mat-icon-button
                                (click)="$event.stopPropagation(); clearFilters(filter.key)"
                            >
                                <mat-icon> close </mat-icon>
                            </button>
                        }
                    }

                    @case ('select') {
                        <mat-select
                            [placeholder]="filter.value.label"
                            multiple
                            color="primary"
                            [value]="_currentValues?.[filter.key]?.value"
                            (valueChange)="applyFilter(filter.key, $event)"
                        >
                            <mat-option *ngFor="let option of filter.value.options | async" [value]="option.value">
                                {{ option.label }}
                            </mat-option>
                        </mat-select>
                        @if (values?.[filter.key]?.value && !filter.value.required) {
                            <button
                                class="clear-filter-btn"
                                mat-icon-button
                                (click)="$event.stopPropagation(); clearFilters(filter.key)"
                            >
                                <mat-icon> close </mat-icon>
                            </button>
                        }
                    }

                    @case ('switch') {
                        <mat-slide-toggle
                            labelPosition="before"
                            color="primary"
                            [checked]="_currentValues?.[filter.key]?.value"
                            (change)="applyFilter(filter.key, $event.checked)"
                        >
                            {{ filter.value.label }}
                        </mat-slide-toggle>
                    }

                    @case ('date-range') {
                        <button mat-button (click)="rangePicker.open()">
                            <div class="ctrl-hide">
                                <mat-date-range-input
                                    #rangeInput
                                    [rangePicker]="rangePicker"
                                    [min]="filter.value.min"
                                    [max]="filter.value.max"
                                >
                                    <input
                                        matStartDate
                                        readonly
                                        [value]="_currentValues?.[filter.key]?.value?.start"
                                        (dateChange)="dateRangeStartChanged($event, filter.key, rangeInput)"
                                    />
                                    <input
                                        matEndDate
                                        readonly
                                        [value]="_currentValues?.[filter.key]?.value?.end"
                                        (dateChange)="dateRangeEndChanged($event, filter.key)"
                                    />
                                </mat-date-range-input>
                                <mat-date-range-picker
                                    panelClass="with-custom-header"
                                    #rangePicker
                                    [id]="filter.key"
                                    [calendarHeaderComponent]="_dateRangeHeader"
                                    (closed)="patchDateRangeValue(filter.key)"
                                >
                                    <mat-date-range-picker-actions>
                                        <button
                                            class="btn-big"
                                            mat-button
                                            (click)="clearFilters(filter.key, rangePicker)"
                                            [ngClass]="{ invisible: !values?.[filter.key]?.value?.start }"
                                        >
                                            Limpiar
                                        </button>
                                        <button
                                            class="btn-big"
                                            color="primary"
                                            mat-raised-button
                                            matDateRangePickerApply
                                        >
                                            Aplicar
                                        </button>
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                            </div>
                            <div class="d-flex align-items-center">
                                <mat-icon class="mr-2">calendar_month</mat-icon>
                                <div class="placeholder">
                                    {{ values?.[filter.key]?.displayValue || filter.value.label }}
                                </div>
                                @if (values?.[filter.key]?.value && !filter.value.required) {
                                    <button
                                        class="clear-filter-btn"
                                        mat-icon-button
                                        (click)="$event.stopPropagation(); clearFilters(filter.key)"
                                    >
                                        <mat-icon> close </mat-icon>
                                    </button>
                                }
                            </div>
                        </button>
                    }

                    @case ('date') {
                        <button mat-button (click)="datepicker.open()">
                            <div class="ctrl-hide">
                                <input
                                    matInput
                                    [matDatepicker]="datepicker"
                                    [value]="_currentValues?.[filter.key]?.value"
                                    [min]="filter.value.min"
                                    [max]="filter.value.max"
                                    (dateChange)="applyFilter(filter.key, $event.value)"
                                />
                                <mat-datepicker #datepicker></mat-datepicker>
                            </div>
                            <mat-icon class="mr-2">calendar_month</mat-icon>
                            <div class="inline">
                                <div class="placeholder">
                                    {{ values?.[filter.key]?.displayValue || filter.value.label }}
                                </div>
                                @if (values?.[filter.key]?.value && !filter.value.required) {
                                    <button
                                        class="clear-filter-btn"
                                        mat-icon-button
                                        (click)="$event.stopPropagation(); clearFilters(filter.key)"
                                    >
                                        <mat-icon> close </mat-icon>
                                    </button>
                                }
                            </div>
                        </button>
                    }
                }
            </div>
        </div>
        @if (clearable) {
            <div class="clear-filters">
                <button mat-button (click)="clearFilters()">Limpiar filtros</button>
            </div>
        }
    </div>
    @if (showScrollCtrls) {
        <button class="scroll-ctrl ctrl-next" (click)="scrollNextElem('R')">
            <mat-icon>chevron_right</mat-icon>
        </button>
    }
</div>
